<script>
import EpisodeRowItem from "../episodes/EpisodeRowItem.vue";
import Management from "./_Management.vue";
import ContentAbout from "./_About.vue";

export default {
  data() {
    return {
    }
  },
  props: {
    data: {
      type: Object,
      default: function () {
        return {
          episodes: [],
          tags: [],
        }
      }
    }
  },
  components: {
    EpisodeRowItem,
    ContentAbout,
    Management,
  },
  computed: {
    isFounderOrAuthor: function() {
      return localStorage.getItem('user.id') !== null &&
        (this.data.podcast.permissions.isFounder || this.data.podcast.permissions.isAuthor);
    }
  }
};
</script>

<template>

  <ContentAbout v-bind:data="data"></ContentAbout>
  <Management v-if="isFounderOrAuthor" v-bind:link="data.podcast.link"></Management>

  <div class="page-title-box d-sm-flex align-items-center justify-content-between" v-if="data.drafts !== null && data.drafts.length > 0">
    <h4 class="mb-sm-0">Drafts</h4>
  </div>
  <div class="team-list list-view-filter row mb-3" v-if="data.drafts !== null && data.drafts.length > 0">
    <div v-for="p in data.drafts" v-bind:key="p">
      <EpisodeRowItem v-bind:data="p"></EpisodeRowItem>
    </div>
  </div>

  <div class="page-title-box d-sm-flex align-items-center justify-content-between" v-if="data.episodes !== null && data.episodes.length > 0">
    <h4 class="mb-sm-0">Episodes</h4>
  </div>
  <div class="team-list list-view-filter row" v-if="data.episodes !== null && data.episodes.length > 0">
    <div v-for="p in data.episodes" v-bind:key="p">
      <EpisodeRowItem v-bind:data="p"></EpisodeRowItem>
    </div>
  </div>
</template>