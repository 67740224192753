<script>
import axios from 'axios';

export default {
  data() {
    return {
      favClass: 'ri-bookmark-line',
      favCount: 0,
    }
  },
  props: {
    data: {
      type: Object,
      default: function () {
        return {
          guests: []
        }
      }
    }
  },
  components: {
  },
  mounted() {
    if(this.data.stat.isFavourite) {
      this.favClass = 'ri-bookmark-fill text-warning';
    }
    else {
      this.favClass = 'ri-bookmark-line';
    }
    this.favCount = this.data.stat.favourites;
  },
  methods: {
    setOrRemoveFavourite(id) {
      const vm = this;
      axios.post('/api/favourites/podcast/' + id, {}, { withCredentials: true }).then(function (e) {
        if(e.data.toggle) {
          vm.favClass = 'ri-bookmark-fill text-warning';
        } else {
          vm.favClass = 'ri-bookmark-line';
        }
        vm.favCount = e.data.count;
      }).catch(function () {
      });
    },
  }
};
</script>

<template>
  <div class="card mb-4">
    <div class="card-body">
      <div class="d-flex mb-3">
        <h6 class="card-title mb-0 flex-grow-1">Actions</h6>
      </div>
      <b-button variant="primary" size="sm" @click="setOrRemoveFavourite(data.id)" class="btn btn-sm btn-light">
        <span class="me-1"><i :class="favClass"></i></span> {{ favCount }}
      </b-button>
    </div>
  </div>
</template>
