<script>

export default {
  data() {
    return {
    }
  },
  props: {
    link: String,
    data: {
      type: Object,
      default: function () {
        return {
        }
      }
    }
  },
  components: {
  },
  computed: {
  }
};
</script>

<template>
  <div class="mb-4">

    <div class="row align-items-center team-row">
      <div class="col team-settings">
        <router-link :to="{ name: 'create-episode', params: { name: this.link } }" class="btn btn-primary me-2">
          Create episode
        </router-link>
        <router-link :to="{ name: 'edit-podcast', params: { name: this.link } }" class="btn btn-primary">
          Edit podcast
        </router-link>
      </div>
    </div>

  </div>
</template>
